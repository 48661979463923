.container {
    max-width: 80%; /* Adjusted to create space around the edges */
    margin: 10% auto; /* Increased margin to keep distance from the edge */
}

.main-area {
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 20px;
}

/* Add this for the logo */
.logo {
    display: block;
    margin: 0 auto 20px auto; /* Center the logo and add margin below */
    width: 50%; /* Reduce the size of the logo */
}

.table {
    text-align: center; /* Center-align text in the table */
}

.table th,
.table td {
    vertical-align: middle; /* Center-align vertically */
}

/* Footer CSS */
.footer {
    text-align: right;
    margin-top: 20px;
    font-size: 0.8rem;
    position: absolute;
    right: 10%;
    bottom: 10px; /* Adjust as needed to ensure it's at the bottom */
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .container {
        max-width: 95%;
        margin: 5% auto; /* Reduce margin for smaller screens */
    }
    .footer {
        right: 5%; /* Adjust right margin for smaller screens */
    }
    .table-responsive table {
        width: 100%;
        display: block;
    }
    .main-area h2 {
        font-size: 1.5rem; /* Smaller font size for the heading */
    }
    .btn {
        padding: 0.5rem 1rem; /* Smaller padding for buttons */
        font-size: 0.875rem; /* Smaller font size for button text */
    }
}

.table-responsive {
    overflow-x: auto;
}

.booking-form-container {
    padding: 20px;
    margin: 10% auto;
    width: 90%;
    max-width: 600px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.booking-form {
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
}

.form-group input,
.form-group select,
.form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.btn-submit {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.btn-submit:hover {
    background-color: #45a049;
}

.btn-toggle {
    margin-left: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    padding: 0.375rem 0.75rem;
}

.btn-toggle:hover {
    background-color: #0056b3;
}

.image-preview {
    max-width: 40%;
    display: block; /* Ensure it's displayed as block level for proper margin handling */
    margin: 10px auto; /* Center the image with margin */
}
